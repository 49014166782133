/* Add here all your CSS customizations */


.transparent-bg {
    background-color: transparent;
    background: transparent;
}

.accordion .card-header a {
    color: #214d8a !important;
}

html .toggle-primary .toggle.active > label {
    background-color: transparent !important;
    background: transparent !important;
}

.holds-the-iframe {
    background:url(/img/loader.gif) center center no-repeat;
  }